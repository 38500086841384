<template>
    <div>
        <Preloader v-if="isLoading" />
        <router-view></router-view>
        <BackToTop />
    </div>
</template>

<script>
    import Preloader from './components/Layout/Preloader'
    import BackToTop from './components/Layout/BackToTop'

    export default {
        name: 'App',
        components: {
            Preloader,
            BackToTop,
        },
        data() {
            return {
                isLoading: false
            }
        },
        mounted() {
            //setTimeout(() => {
            //    this.isLoading = false
            //}, 2000)
        }
    }
</script>