import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import { BootstrapVue } from 'bootstrap-vue'
import VueCarousel from 'vue-carousel'
import BackToTop from 'vue-backtotop'
import VueCoolLightbox from 'vue-cool-lightbox'
import Vuex from 'vuex'
import Toasted from 'vue-toasted'
import store from './store'
import './assets/custom.scss'

import { router } from './router'

Vue.use(VueRouter)
Vue.use(BootstrapVue)
Vue.use(VueCarousel)
Vue.use(BackToTop)
Vue.use(VueCoolLightbox)
Vue.use(Vuex)
Vue.use(Toasted)

Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
