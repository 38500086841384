import VueRouter from 'vue-router'

export const router = new VueRouter({
    mode: 'history',
    linkExactActiveClass: 'active',
    scrollBehavior(){
        return { x: 0, y: 0 }
    },

    routes: [
        { path: '/', component: () => import('./active-components/Home') },

        { path: '/jobs', component: () => import('./active-components/Jobs/Index')},
        //{ path: '/jobs/dev01', component: () => import('./active-components/Jobs/Details/GenericDev')},
        //{ path: '/jobs/csa', component: () => import('./active-components/Jobs/Details/CustomerSuccessAgent')},
        //{ path: '/jobs/fullstack-cfcloud', component: () => import('./active-components/Jobs/Details/FullstackEngineer')},
        { path: '/jobs/ws-cfcloud', component: () => import('./active-components/Jobs/Details/WorkingStudentEngineer')},
        { path: '/jobs/frontend-engineer', component: () => import('./active-components/Jobs/Details/FrontendEngineer.vue')},

        //{ path: '/projects', component: () => import('./active-components/Projects')},
        //{ path: '/projects/cftools-cloud', component: () => import('./active-components/ProjectOmega')},

        { path: '/contact', component: () => import('./active-components/Contact')},

        { path: '/imprint', component: () => import('./active-components/Imprint')},
        { path: '/privacy', component: () => import('./active-components/PrivacyPolicy')},
        { path: '/legal/privacy', component: () => import('./active-components/PrivacyPolicy')},


    ]
})